import React from "react";
import axios from 'axios';

import { useTranslation } from 'react-i18next';

import Layout from "../components/Layout";
import DealBox from "../components/Deal";


function compareStrings(s1, s2) {
  let correct = 0;
  for (let i = 0; i < s1.length; i++) {
    if (s1.charAt(i) == s2.charAt(i)) {
      correct += 1;
    }
  }

  return (correct / s1.length) * 100
}


const DealsScreen = () => {

  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(true)
  
  const [deals, setDeals] = React.useState([])
  const [filtered, setFiltered] = React.useState([])

  const [month, setMonth] = React.useState(new Date().getMonth() + 1)
  const [year, setYear] = React.useState(new Date().getFullYear())
  
  const [avg, setAvg] = React.useState('50')
  const [count, setCount] = React.useState('5')

  
  const formatAndFilterDeals = (results) => {
    // clone
    let new_results = results.filter(a => a.location === 'hanoi').map(a => {return {...a}})
    
    for (let i = new_results.length - 1; i >= 0; i--){
      
      let dt = new Date(Date.parse(new_results[i].expiry))
      // get minutes difference between current time and deal expiry time
      let diff = (dt.getTime() - new Date(Date.now())) / 1000 / 60
      // console.log(new Date(Date.now()), dt, diff)
      new_results[i].diff = diff
    }
    new_results = new_results.sort((a, b) => a.diff < 0 ? a.diff < b.diff : a.diff > b.diff)
    for (let i = new_results.length - 1; i >= 0; i--){
      
      if (typeof new_results[i] !== 'undefined' && typeof new_results[i-1] !== 'undefined' && compareStrings(new_results[i-1].title, new_results[i].title) > 50 ){
        // console.log('duplicate deal ', new_results[i-1].title, new_results[i].title)
        new_results.splice(i, 1)
        continue
      }
    }
    return new_results
  }
    
  const fetch_deals = async () => {
    
    // get current year
    // zero padded month
    let month_year =  year+'-'+month.toString().padStart(2, '0')
    
    let results = []
    let tmp_deals = []
    let businesses = {}
    let res = null

    try{
      res = await axios.get('/deals/businesses.json')
      res.data.map(b => businesses[b['id']] = b)
    }
    catch(e){
      console.log('no deals this month yet?')        
    }

    // load last 3 months of data from await axios.get('/deals/deals_' + month_year + '.json')
    console.log('loading ... ', month_year)
    try{
      res = await axios.get('/deals/deals_' + month_year + '.json')
      tmp_deals = formatAndFilterDeals(res.data)
      setDeals(tmp_deals)
      
    }
    catch(e){
      console.log('no deals this month yet?')        
    }
    let tmp_month = month

    let tmp_year = year
    for (let i = 0; i < 3; i++){
      tmp_month = tmp_month - 1

      if (tmp_month === 0){
        tmp_month = 12
        tmp_year = tmp_year - 1
      }
      // month = (month - 1).toString().padStart(2, '0')
      month_year =  tmp_year+'-'+tmp_month.toString().padStart(2, '0')
      
      try{
        res = await axios.get('/deals/deals_' + month_year + '.json')
        results = formatAndFilterDeals(res.data)
        // console.log('results ', month_year,  [...deals, ...results].length)
        tmp_deals = [...tmp_deals, ...results]
        setDeals(tmp_deals)
      }
      catch(e){
        console.log('no deals this month yet?')        
      }
    }
    setYear(tmp_year)
    setMonth(tmp_month)

  }

  const loadMore = () => {
    let tmp_month = month - 1
    let tmp_year = year
    if (tmp_month === 0){
      tmp_month = 12
      tmp_year = tmp_year - 1
    }
    // month = (month - 1).toString().padStart(2, '0')
    const month_year =  tmp_year+'-'+tmp_month.toString().padStart(2, '0')
      
    axios.get('/deals/deals_' + month_year + '.json')
      .then((res) => {
        const results = formatAndFilterDeals(res.data.sort((a, b) => parseInt(a.id) < parseInt(b.id)))
        // console.log('results ', month_year,  [...deals, ...results].length)
        setDeals([...deals, ...results])
      })
      .catch(e =>console.log('no deals this month yet?'))
    
    setYear(tmp_year)
    setMonth(tmp_month)
  }
  

  React.useEffect(() => {
    
    if (!loading){
      return
    }
    console.log('fetching deals !!!!', loading)
    setLoading(false)
      
    fetch_deals()
    
    let script = document.createElement("script")
    script.setAttribute("id", 'deals')
    script.setAttribute("src", '/js/baobab/deals.js');
    
    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }
    
  }, [loading])

  React.useEffect(() => {
    setFiltered(deals)

    // get avarage discount_perc
    let sum = 0
    let tmp_count = 0
    let last_7_days_count = 0
    
    deals.map(d => {
      if (d.diff >= -60*24*90){
        last_7_days_count += 1
      }
      sum += d.discounted_perc
      tmp_count += 1
    })

    setCount(last_7_days_count)
    setAvg(Math.round(sum / tmp_count))

  }, [deals])

  // const onFilter = (e) => {
  //   let value = e.target.value
  //   let filtered = deals.filter((d) => 
  //     d.title.toLowerCase().includes(value.toLowerCase())
  //     || d.business_name.toLowerCase().includes(value.toLowerCase())
  //   )
  //   setFiltered(filtered)
  // }

  
  return (
    <Layout showDownload={true} >

  {/* SubHeader =============================================== */}
  <section id="short" style={{ backgroundImage: "url('img/hanoi.jpg')", backgroundSize: 'cover' }} >
    <div id="subheader">
      <div id="sub_content">
        <h1>{count} {t('deals.title', "deals over last 90 days in Hanoi")}</h1>
        <div>
          <i className="icon_pin" /> {t('deals.sub_title', "with avarage discount of")} {avg}% 
        </div>
      </div>
      {/* End sub_content */}
    </div>
    {/* End subheader */}
  </section>
  {/* End section */}

  {/* Content ================================================== */}
  <div className="container margin_60_35">
    <div className="row">
      <div className="col-lg-3 hide-small">
        <div id="filters_col" className="deals_banner">
          
        </div>
        {/*End filters col*/}
      </div>
      {/*End col-md */}
      <div className="col-lg-9" style={{ padding: '0px 8px'}}>
        { filtered.length === 0 && 
          <div className="sk-spinner" style={{ marginTop: '250px', margin: 'auto', textAlign: 'center'}}>
            <div className="lds-hourglass"/>
          </div> 
        }
        {/* { filtered.length > 0 &&
          <div className="form-group" style={{ padding: '0px', marginBottom: '10px'}}>
              <input type="text" className="form-control" placeholder="&#x1F50D;" style={{filter: "grayscale(100%)"}} onChange={onFilter} />
          </div>
        } */}
        <div className="business-list" style={{ marginTop: '0px'}}>
          { filtered.sort((a, b) => a.diff < 0 ? a.diff < b.diff : a.diff > b.diff).map(
            (deal, index) => <DealBox key={index} deal={deal} />) 
          } 
          { filtered.length > 0 &&
          <button className="load-more-button" onClick={loadMore}>{t('deals.load_more', "Load more")}</button>
          }
        </div>
      </div>
      {/* End col-md-9*/}
    </div>
    {/* End row */}
  </div>
  {/* End container */}
  {/* End Content =============================================== */}

    </Layout>
  
    )
}

export default DealsScreen