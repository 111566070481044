import React from "react";
import $ from 'jquery'

// import { useTranslation } from 'react-i18next';


const Header = ( props ) => {

    
    React.useEffect(() => {
        
        
        // load scripts 
        var script = document.createElement("script")
        script.setAttribute("id", 'functions')
        script.setAttribute("src", '/js/functions.js');
        
        try {
            document.head.appendChild(script);
        } catch (e) {
            console.log(e)
        }
        
          
    
    }, [])
  
  

    return (
    <header className={props.className}>
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-4 col-4">
                <a href="/" id="logo">
                    <img
                    style={{ marginTop: '-10px', marginLeft: '20px' }}
                    src="/logo.png"
                    width={109}
                    height={40}
                    alt=""
                    className="d-none d-sm-block"
                    />
                    <img
                    src="/logo.png"
                    style={{ margin: '-6px 0px' }}
                    width={109}
                    height={40}
                    alt=""
                    className="d-block d-sm-none"
                    />
                </a>
                </div>
                <nav className="col-md-8 col-sm-8 col-8">
                <a className="cmn-toggle-switch cmn-toggle-switch__htx open_close" onClick={() => {
                    $(".main-menu").toggleClass("show"); 
                    $(".layer").toggleClass("layer-is-visible") 
                }}>
                    <span>{props.t('header.menu', 'menu')}</span>
                </a>
                <div className="main-menu">
                    <div id="header_menu">
                    <img src="/logo.png" width={109} height={40} alt="" />
                    </div>
                    <a href="#" className="open_close" id="close_in" onClick={() => {
                        $(".main-menu").toggleClass("show"); 
                        $(".layer").toggleClass("layer-is-visible") 
                    }}>
                    <i className="icon_close" />
                    </a>
                    <ul>
                    <li>
                        <a href="/">{props.t('header.home', 'Home')}</a>
                    </li>

                    <li>
                        <a href="/partners">{props.t('header.partners', 'Partners')}</a>
                    </li>
                    <li>
                        <a href="/deals">{props.t('header.deals', 'Deals')}</a>
                    </li>
                    <li>
                        <a href="/about-us">{props.t('header.about', 'About us')}</a>
                    </li>
                    <li>
                        <a href="/contact">{props.t('header.contact', 'Contact')}</a>
                    </li>
                    <li class="submenu">
                    <a href="#" class="show-submenu" >{props.t('header.language', 'Language')}<i class="icon-down-open-mini"></i></a>
                    <ul class="show_normal">
                    <li>
                        <a href="#" onClick={() => props.changeLanguage('en')}>en</a>
                    </li>
                    <li>
                        <a href="#" onClick={() => props.changeLanguage('vn')}>vn</a>
                    </li>
                    </ul>
                    </li>
                    </ul>
                </div>
                </nav>
            </div>
            </div>
        </header>
    )
}
export default Header;


