import React from "react";
import axios from "axios";
import {
  useParams, useSearchParams, useLocation
} from "react-router-dom";

import GoogleMapsSingleMarker from "../components/GoogleMapsSingleMarker";

import Layout from "../components/Layout";
import DealExtendedBox from "../components/DealExtended";


const DealScreen = () => {

  const l = useLocation()

  const queryParams = l.hash?.split('?id=')
  // console.log(queryParams)
  const params = useParams()
  let id = null
  if (queryParams && queryParams.length > 1) {
    id = queryParams[1]
  }
  else{
    id = params['id'];
  }
  // let { id } = useParams();
  const [deal, setDeal] = React.useState([])

  React.useEffect(() => {
    
    const fetch_deals = async () => {
      try{
          let res = await axios.get('/deals/' + id + '.json')
          console.log(res)
          let dt = new Date(Date.parse(res.data.expiry))
          // get minutes difference between current time and deal expiry time
          let diff = (dt.getTime() - new Date(Date.now())) / 1000 / 60
      
          setDeal({...res.data, diff: diff    })
      }
      catch(e){
          console.log('missing deal'+ id)
      }
    }
    
    fetch_deals()
  
    let script = document.createElement("script")
    script.setAttribute("id", 'partner')
    script.setAttribute("src", '/js/baobab/partner.js');
    
    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }

  }, [])

  if (!deal) {
    return null
  }


  return (
    <Layout showDownload={true} >
      {/* SubHeader =============================================== */}
  <section
    className="parallax-window"
    id="short"
    data-parallax="scroll"
    data-natural-width={1400}
    data-natural-height={350}
  >
    <div id="subheader"  style={{ paddingTop: '50px', backgroundColor: 'rgba(0,113,65)'}}>
      <GoogleMapsSingleMarker 
          marker={ {id: deal.id, name: deal.business_name, desc: deal.business_address, lat: deal.business_latitude, lng: deal.business_longitude } } 
       />
    </div>

  </section>
  {/* End section */}
  {/* End SubHeader ============================================ */}
  
  {/* Content ================================================== */}
  <div className="container margin_60_35" >
    <div className="row">
      <div className="col-lg-4">
        <div className="box_style_2 d-none d-lg-block" >
            <img
              alt=""
              className="deal_business_logo"
              src={deal.business_logo}
            />
            <h3 style={{paddingLeft: '8px'}}>{deal.business_name}</h3>
            <h5 style={{paddingLeft: '8px'}}><i>{deal.business_address}</i></h5>
          
        </div>
        
      </div>
      <div className="col-lg-8 no_margin">
        <DealExtendedBox deal={deal} />
        {/* End box_style_1 */}
      </div>
    </div>
    {/* End row */}
  </div>
  {/* End container */}
  {/* End Content =============================================== */}

    </Layout>
  
    )
}

export default DealScreen