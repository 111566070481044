import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow, MarkerLabel } from 'google-maps-react';


const _mapLoaded = (mapProps, map) => {
    map.setOptions({
        styles: mapStyle
    })
}

const API_KEY = "AIzaSyCR1WOkIl5fzI-DPpE9F7uK4EMDkh2Nt2g"

const GoogleMaps2 = ({ google, markers, mapCenter, active, markerCallback }) => {
  
  const [map, setMap] = React.useState(null);
  const [activeMarker, setActiveMarker] = React.useState(null)
  const [infoWindowOpen, setInfoWindowOpen] = React.useState(false);
  
  React.useEffect(() => {
    if (map && activeMarker) {
      setInfoWindowOpen(true);
    }
    else {
      console.log('close info')
      setInfoWindowOpen(false);
    }
  }, [map, activeMarker]);
  
  
  React.useEffect(() => {
    if (mapRef.current && mapCenter && mapCenter.lat && mapCenter.lng) {
      mapRef.current.map.panTo({lat: parseFloat(mapCenter.lat), lng: parseFloat(mapCenter.lng)})
    }
  
  }, [mapCenter])
  
  
  // React.useEffect(() => {
  //   setActiveMarker(null)  
  // }, [markers])

  React.useEffect(() => {
    // if ((!active && !activeMarker) || (activeMarker && activeMarker.id === active)){
    //   return
    // }
    // console.log('set', markerRefs.current[active])
    setActiveMarker(markers.find(m => m.id === active))
    
  }, [markers, active])
  

  const mapRef = React.useRef(null);
  const markerRefs = React.useRef([]);
  
  return (
      <Map
        ref={mapRef}
        google={google}
        zoom={14}
        style={mapStyles}
        onReady={(mapProps, map) => {_mapLoaded(mapProps, map); setMap(map);} }
        initialCenter={
          {
            lat: 21.052,
            lng: 105.83636
          }
        }
      >
        {
          markers.map((marker, index) => (
            <Marker 
              key={marker.id} 
              ref={el => markerRefs.current[marker.id] = el}
              onClick={(props, m) => {
                setActiveMarker(marker);
                markerCallback(marker.id)
              }}
              position={{ lat: parseFloat(marker.lat), lng: parseFloat(marker.lng)}} 
              icon={
                marker.id === active 
                ? { url: "/img/pin-red.png", scaledSize: { width: 36, height: 48 } } 
                : { url: "/img/pin-red.png", scaledSize: { width: 18, height: 24 }}
              } 
            />
              ))
          }

          {
            map && 
            <InfoWindow 
              position={{ lat: activeMarker && activeMarker.lat, lng: activeMarker && activeMarker.lng }}
              visible={infoWindowOpen}
              onCloseClick={() => setInfoWindowOpen(false)}
            >
              <div style={{padding: '10px'}}>
                <h5>{activeMarker && activeMarker.name}</h5>
                <p>{activeMarker && activeMarker.desc}</p>
              </div>
            </InfoWindow>
          }
          
          {/* {
            activeMarker && markerRefs.current[activeMarker.id] && 
          <InfoWindow marker={activeMarker && markerRefs.current[activeMarker.id] ? markerRefs.current[activeMarker.id].marker : null} visible={activeMarker && markerRefs.current[activeMarker.id] ? true : false} >
            <div style={{padding: '10px'}}>
              <h5>{activeMarker && activeMarker.name}</h5>
              <p>{activeMarker && activeMarker.desc}</p>
            </div>
          </InfoWindow>
          } */}
          
      </Map>
  );
};


// const API_KEY = "AIzaSyCR1WOkIl5fzI-DPpE9F7uK4EMDkh2Nt2g"
const GoogleMaps = GoogleApiWrapper({
  apiKey: API_KEY
})(GoogleMaps2);

const mapStyles = {
  width: '100%',
  height: '100%'
};




const mapStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ebe3cd"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#523735"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f1e6"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#c9b2a6"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#dcd2be"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#ae9e90"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#93817c"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#a5b076"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#447530"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f1e6"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#fdfcf8"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f8c967"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#e9bc62"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e98d58"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#db8555"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#806b63"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8f7d77"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ebe3cd"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#b9d3c2"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#92998d"
        }
      ]
    },
    {
      featureType: 'poi.business',
      stylers: [{visibility: 'off'}]
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}]
    }
  ]


export default GoogleMaps