import React from "react";
import i18n from '../i18n';

import { useTranslation } from 'react-i18next';

import Header from "../components/Header";
import Footer from "../components/Footer";
import DownloadButton from "../components/DownloadButton";


const Layout = ({children, stickyHeader, showDownload, showPreloader}) => {

    const [preloaderVisable, setPreloaderVisable] = React.useState(showPreloader || false)
    const [preloaderIconVisable, setPreloaderIconVisable] = React.useState(showPreloader || false)

    const { t, i18n} = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
      }

      
    return (
        <>
            <div id="preloader" style={{ display: preloaderVisable ? 'block' : 'none'}}>
                <div className="sk-spinner sk-spinner-wave" id="status" style={{ display: preloaderIconVisable ? 'block' : 'none'}}>
                <div className="sk-rect1" />
                <div className="sk-rect2" />
                <div className="sk-rect3" />
                <div className="sk-rect4" />
                <div className="sk-rect5" />
                </div>
            </div> 
            <div className="layer"></div>
            <Header className={stickyHeader ? "sticky": ""} changeLanguage={changeLanguage} t={t} i18n={i18n} />
            {children}
            <Footer t={t} />
            { showDownload && <DownloadButton t={t} /> }

        </>
    )
}

export default Layout;