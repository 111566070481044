import { useTranslation } from 'react-i18next';

const DownloadButton = () => {
    const { t } = useTranslation();

    return (
        <a href="/download">
            <button className="download_button">
                <h4 className="download_text">{t('footer.download_btn', 'Download App')}</h4>
                    
            </button>

        </a>
    )
}
export default DownloadButton;