import { useTranslation } from 'react-i18next';




const formatTimeLeft = (minutes, t) => {

    if (minutes > 0 && minutes < 60) {
        return Math.floor(minutes) + t('deal.min_left', 'min left')
    }
    if (minutes > 60 && minutes < 120) {
        return '1h ' + t('deal.left', 'left')
    }
    if (minutes > 120 && minutes < 180) {
        return '2h ' + t('deal.left', 'left')
    }
    if (minutes > 180 && minutes < 240) {
        return '3h ' + t('deal.left', 'left')
    }
    if (minutes > 240 && minutes < 300) {
        return '4h ' + t('deal.left', 'left')
    }
    if (minutes > 300) {
        return '4h ' + t('deal.left', 'left')
    }
    if (minutes <= 0 && minutes > -60) {
        return Math.floor(minutes) + t('deal.min_ago', 'min ago')
    }
    if (minutes <= -60 && minutes > -120) {
        return '1h ' + t('deal.ago', 'ago')
    }
    if (minutes <= -120 && minutes > -60*12) {
        return t('deal.ended_hours_ago', 'few hours ago')
    }
    if (minutes <= -60*12 && minutes > -60*36) {
        return t('deal.ended_yesterday', 'ended yesterday')
    }

    return t('deal.ended', 'ended')

}


const DealExtendedBox = ({deal}) =>  {

    const { t } = useTranslation();

    if (!deal) {
        return null
    }
    
    return (
    <div className='strip_list wow fadeIn deal_extended'   data-wow-delay="0.1s">
        
        <div className="thumb_strip" style={{ width: '100%'}}>
            <img src={deal && deal.image_url} alt="" className="deal_image"/>
        </div>
        <div className="desc">
        <h3 style={{ marginTop: '0px', marginBottom: '0px', paddingTop: '6px'}}>{deal &&  deal.business_name}</h3>
        <h4 style={{ marginTop: '0px', marginBottom: '0px', paddingTop: '16px'}}>{deal &&  deal.title}</h4>
        
        <span className="deal_time_left" style={{ float: 'right', position: 'relative', bottom: '25px'}}>&nbsp;{deal &&  formatTimeLeft(deal.diff, t)}</span>
        
        <div className="type">{deal &&  deal.description}</div>
        
        <div style={{ marginBottom: '4px', marginTop: '2px'}}>
            {t('deal.price', 'price')}: <span style={{ fontWeight: '900', fontSize: '14pxs'}}>{deal &&  deal.discounted_price}K VND</span>
        </div>
        <div style={{ marginBottom: '4px', marginTop: '2px'}}>
            {t('deal.retail', 'retail')}: <span style={{ textDecoration: 'line-through'}}>{deal &&  deal.price}K VND</span> <span style={{ color: '#C33' }}>&nbsp;{deal &&  deal.discounted_perc}% {t('deal.off', 'off')}</span>
        </div>
        <ul>
        <li style={{ paddingTop: '20px'}}>
            {t('deal.eat_in', 'Eat in')}
            <i className="icon_check_alt2 ok" />
            </li>
            <li>
            {t('deal.take_away', 'Take away')}
            <i className="icon_check_alt2 ok" />
            </li>
            <li>
            {t('deal.delivery', 'Delivery')}
            <i className="icon_check_alt2 ok" />
            </li>
        </ul> 
        </div>
    </div>        
)   
}

export default DealExtendedBox