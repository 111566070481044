import React from "react";
import {
  useParams
} from "react-router-dom";


import Layout from "../components/Layout";



const PartnerScreen = () => {

  let { id } = useParams();
  React.useEffect(() => {

    
    // let script = document.createElement("script")
    // script.setAttribute("id", 'map_single')
    // script.setAttribute("src", '/js/map_single.js');
    
    // try {
    //   document.head.appendChild(script);
    // } catch (e) {
    //   console.log(e)
    // }

    // script = document.createElement("script")
    // script.setAttribute("id", 'infobox')
    // script.setAttribute("src", '/js/infobox.js');
    
    // try {
    //   document.head.appendChild(script);
    // } catch (e) {
    //   console.log(e)
    // }
    
    let script = document.createElement("script")
    script.setAttribute("id", 'partner')
    script.setAttribute("src", '/js/baobab/partner.js');
    
    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }

  }, [])


  return (
    <Layout stickyHeader={true}>
      <>
  {/* Content ================================================== */}
  <div className="container margin_60_35">
    <div className="row">
      <div className="col-lg-4">
        <p>
          <a
            className="btn_map"
            data-toggle="collapse"
            href="#collapseMap"
            aria-expanded="false"
            aria-controls="collapseMap"
            data-text-swap="Hide map"
            data-text-original="View on map"
          >
            View on map
          </a>
        </p>
        <div className="box_style_2">
          <h4 className="nomargin_top">
            Opening time <i className="icon_clock_alt float-right" />
          </h4>
          <ul className="opening_list">
            <li>
              Monday<span>12.00am-11.00pm</span>
            </li>
            <li>
              Tuesday<span>12.00am-11.00pm</span>
            </li>
            <li>
              Wednesday <span className="label label-danger">Closed</span>
            </li>
            <li>
              Thursday<span>12.00am-11.00pm</span>
            </li>
            <li>
              Friday<span>12.00am-11.00pm</span>
            </li>
            <li>
              Saturday<span>12.00am-11.00pm</span>
            </li>
            <li>
              Sunday <span className="label label-danger">Closed</span>
            </li>
          </ul>
        </div>
        <div className="box_style_2 d-none d-sm-block" id="help">
          <i className="icon_lifesaver" />
          <h4>
            Need <span>Help?</span>
          </h4>
          <a href="tel://004542344599" className="phone">
            +45 423 445 99
          </a>
          <small>Monday to Friday 9.00am - 7.30pm</small>
        </div>
      </div>
      <div className="col-lg-8">
        <div className="box_style_2">
          <h2 className="inner">Description</h2>
          <div id="Img_carousel" className="slider-pro">
            <div className="sp-slides">
              <div className="sp-slide">
                <img
                  alt=""
                  className="sp-image"
                  src="/css/images/blank.gif"
                  data-src="/img/slider_single_restaurant/1_medium.jpg"
                  data-small="/img/slider_single_restaurant/1_small.jpg"
                  data-medium="/img/slider_single_restaurant/1_medium.jpg"
                  data-large="/img/slider_single_restaurant/1_large.jpg"
                  data-retina="/img/slider_single_restaurant/1_large.jpg"
                />
              </div>
              <div className="sp-slide">
                <img
                  alt=""
                  className="sp-image"
                  src="/css/images/blank.gif"
                  data-src="/img/slider_single_restaurant/2_medium.jpg"
                  data-small="/img/slider_single_restaurant/2_small.jpg"
                  data-medium="/img/slider_single_restaurant/2_medium.jpg"
                  data-large="/img/slider_single_restaurant/2_large.jpg"
                  data-retina="/img/slider_single_restaurant/2_large.jpg"
                />
              </div>
              <div className="sp-slide">
                <img
                  alt=""
                  className="sp-image"
                  src="/css/images/blank.gif"
                  data-src="/img/slider_single_restaurant/3_medium.jpg"
                  data-small="/img/slider_single_restaurant/3_small.jpg"
                  data-medium="/img/slider_single_restaurant/3_medium.jpg"
                  data-large="/img/slider_single_restaurant/3_large.jpg"
                  data-retina="/img/slider_single_restaurant/3_large.jpg"
                />
              </div>
              <div className="sp-slide">
                <img
                  alt=""
                  className="sp-image"
                  src="/css/images/blank.gif"
                  data-src="/img/slider_single_restaurant/4_medium.jpg"
                  data-small="/img/slider_single_restaurant/4_small.jpg"
                  data-medium="/img/slider_single_restaurant/4_medium.jpg"
                  data-large="/img/slider_single_restaurant/4_large.jpg"
                  data-retina="/img/slider_single_restaurant/4_large.jpg"
                />
              </div>
              <div className="sp-slide">
                <img
                  alt=""
                  className="sp-image"
                  src="/css/images/blank.gif"
                  data-src="/img/slider_single_restaurant/5_medium.jpg"
                  data-small="/img/slider_single_restaurant/5_small.jpg"
                  data-medium="/img/slider_single_restaurant/5_medium.jpg"
                  data-large="/img/slider_single_restaurant/5_large.jpg"
                  data-retina="/img/slider_single_restaurant/5_large.jpg"
                />
              </div>
              <div className="sp-slide">
                <img
                  alt=""
                  className="sp-image"
                  src="/css/images/blank.gif"
                  data-src="/img/slider_single_restaurant/6_medium.jpg"
                  data-small="/img/slider_single_restaurant/6_small.jpg"
                  data-medium="/img/slider_single_restaurant/6_medium.jpg"
                  data-large="/img/slider_single_restaurant/6_large.jpg"
                  data-retina="/img/slider_single_restaurant/6_large.jpg"
                />
              </div>
              <div className="sp-slide">
                <img
                  alt=""
                  className="sp-image"
                  src="/css/images/blank.gif"
                  data-src="/img/slider_single_restaurant/7_medium.jpg"
                  data-small="/img/slider_single_restaurant/7_small.jpg"
                  data-medium="/img/slider_single_restaurant/7_medium.jpg"
                  data-large="/img/slider_single_restaurant/7_large.jpg"
                  data-retina="/img/slider_single_restaurant/7_large.jpg"
                />
              </div>
              <div className="sp-slide">
                <img
                  alt=""
                  className="sp-image"
                  src="/css/images/blank.gif"
                  data-src="/img/slider_single_restaurant/8_medium.jpg"
                  data-small="/img/slider_single_restaurant/8_small.jpg"
                  data-medium="/img/slider_single_restaurant/8_medium.jpg"
                  data-large="/img/slider_single_restaurant/8_large.jpg"
                  data-retina="/img/slider_single_restaurant/8_large.jpg"
                />
              </div>
              <div className="sp-slide">
                <img
                  alt=""
                  className="sp-image"
                  src="/css/images/blank.gif"
                  data-src="/img/slider_single_restaurant/9_medium.jpg"
                  data-small="/img/slider_single_restaurant/9_small.jpg"
                  data-medium="/img/slider_single_restaurant/9_medium.jpg"
                  data-large="/img/slider_single_restaurant/9_large.jpg"
                  data-retina="/img/slider_single_restaurant/9_large.jpg"
                />
              </div>
            </div>
            <div className="sp-thumbnails">
              <img
                alt=""
                className="sp-thumbnail"
                src="/img/slider_single_restaurant/1_medium.jpg"
              />
              <img
                alt=""
                className="sp-thumbnail"
                src="/img/slider_single_restaurant/2_medium.jpg"
              />
              <img
                alt=""
                className="sp-thumbnail"
                src="/img/slider_single_restaurant/3_medium.jpg"
              />
              <img
                alt=""
                className="sp-thumbnail"
                src="/img/slider_single_restaurant/4_medium.jpg"
              />
              <img
                alt=""
                className="sp-thumbnail"
                src="/img/slider_single_restaurant/5_medium.jpg"
              />
              <img
                alt=""
                className="sp-thumbnail"
                src="/img/slider_single_restaurant/6_medium.jpg"
              />
              <img
                alt=""
                className="sp-thumbnail"
                src="/img/slider_single_restaurant/7_medium.jpg"
              />
              <img
                alt=""
                className="sp-thumbnail"
                src="/img/slider_single_restaurant/8_medium.jpg"
              />
              <img
                alt=""
                className="sp-thumbnail"
                src="/img/slider_single_restaurant/9_medium.jpg"
              />
            </div>
          </div>
          <h3>About us</h3>
          <p>
            Lorem ipsum dolor sit amet, ius sonet meliore partiendo cu. Nobis
            laudem mediocrem cum et. Debitis nonumes similique te eam, blandit
            eligendi principes sea no. Cu eum quidam expetendis.
          </p>
          <p className="add_bottom_30">
            Lorem ipsum dolor sit amet, ex has recusabo adipiscing, aliquip
            alienum at vis, eos id qualisque quaerendum. Soleat habemus duo no,
            te quo dicam everti, sale ullum movet per ea. Cu cum laudem quaeque,
            agam decore nullam ei vis. Nec ad tota etiam eirmod. Harum debitis
            detraxit ut vel, eu vel option oporteat.
          </p>
          <div id="summary_review">
            <div id="general_rating">
              11 Reviews
              <div className="rating">
                <i className="icon_star voted" />
                <i className="icon_star voted" />
                <i className="icon_star voted" />
                <i className="icon_star voted" />
                <i className="icon_star" />
              </div>
            </div>
            <div className="row" id="rating_summary">
              <div className="col-md-6">
                <ul>
                  <li>
                    Food Quality
                    <div className="rating">
                      <i className="icon_star voted" />
                      <i className="icon_star voted" />
                      <i className="icon_star voted" />
                      <i className="icon_star" />
                      <i className="icon_star" />
                    </div>
                  </li>
                  <li>
                    Price
                    <div className="rating">
                      <i className="icon_star voted" />
                      <i className="icon_star voted" />
                      <i className="icon_star voted" />
                      <i className="icon_star voted" />
                      <i className="icon_star" />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>
                    Punctuality
                    <div className="rating">
                      <i className="icon_star voted" />
                      <i className="icon_star voted" />
                      <i className="icon_star" />
                      <i className="icon_star" />
                      <i className="icon_star" />
                    </div>
                  </li>
                  <li>
                    Courtesy
                    <div className="rating">
                      <i className="icon_star voted" />
                      <i className="icon_star voted" />
                      <i className="icon_star voted" />
                      <i className="icon_star voted" />
                      <i className="icon_star" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* End row */}
            <hr className="styled" />
            <a
              href="#"
              className="btn_1 add_bottom_15"
              data-toggle="modal"
              data-target="#myReview"
            >
              Leave a review
            </a>
          </div>
          {/* End summary_review */}
          <div className="review_strip_single">
            <img src="/img/avatar1.jpg" alt="" className="rounded-circle" />
            <small> - 10 March 2015 -</small>
            <h4>Jhon Doe</h4>
            <p>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a
              lorem quis neque interdum consequat ut sed sem. Duis quis tempor
              nunc. Interdum et malesuada fames ac ante ipsum primis in
              faucibus."
            </p>
            <div className="row">
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                </div>
                Food Quality
              </div>
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                </div>
                Price
              </div>
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star" />
                </div>
                Punctuality
              </div>
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star" />
                </div>
                Courtesy
              </div>
            </div>
            {/* End row */}
          </div>
          {/* End review strip */}
          <div className="review_strip_single">
            <img src="/img/avatar3.jpg" alt="" className="rounded-circle" />
            <small> - 25 March 2015 -</small>
            <h4>Markus Schulz</h4>
            <p>
              "At sed dico invenire facilisis, sed option sapientem iudicabit
              ad, sea idque doming vituperatoribus at. Duo ut inani tantas
              scaevola. Commodo oblique at cum. Duo id vide delectus. Vel et
              doctus laoreet minimum, ei feugait pertinacia usu.
            </p>
            <div className="row">
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                </div>
                Food Quality
              </div>
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                </div>
                Price
              </div>
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                </div>
                Punctuality
              </div>
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star" />
                </div>
                Courtesy
              </div>
            </div>
            {/* End row */}
          </div>
          {/* End review strip */}
          <div className="review_strip_single last">
            <img src="/img/avatar2.jpg" alt="" className="rounded-circle" />
            <small> - 10 April 2015 -</small>
            <h4>Frank Cooper</h4>
            <p>
              "Ne mea congue facilis eligendi, possit utamur sensibus id qui,
              mel tollit euismod alienum eu. Ad tollit lucilius praesent per, ex
              probo utroque placerat eos. Tale verear efficiendi et cum, meis
              timeam vix et, et duis debet nostro mel. Aeterno labitur per no,
              id nec tantas nemore. An minim molestie per, mei sumo vulputate
              cu."
            </p>
            <div className="row">
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                </div>
                Food Quality
              </div>
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                </div>
                Price
              </div>
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                </div>
                Punctuality
              </div>
              <div className="col-md-3">
                <div className="rating">
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star voted" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                </div>
                Courtesy
              </div>
            </div>
            {/* End row */}
          </div>
          {/* End review strip */}
        </div>
        {/* End box_style_1 */}
      </div>
    </div>
    {/* End row */}
  </div>
  {/* End container */}
  {/* End Content =============================================== */}
</>

    </Layout>
  
    )
}

export default PartnerScreen