import React from "react";
import axios from 'axios';

import DealBox from "../components/Deal";
import Layout from "../components/Layout";

import { useTranslation } from 'react-i18next';


const Deal = ({ promoDeals }) => {
    if (!promoDeals)  {
        return null
    }

    return (
        <div className="row">
                <div className="col-lg-6">
                { typeof promoDeals[0] !== 'undefined' && DealBox({deal: promoDeals[0]})}
                { typeof promoDeals[1] !== 'undefined' && DealBox({deal: promoDeals[1]})}
                { typeof promoDeals[2] !== 'undefined' && DealBox({deal: promoDeals[2]})}
                </div>
                <div className="col-lg-6">
                { typeof promoDeals[3] !== 'undefined' && DealBox({deal: promoDeals[3]})}
                { typeof promoDeals[4] !== 'undefined' && DealBox({deal: promoDeals[4]})}
                { typeof promoDeals[5] !== 'undefined' && DealBox({deal: promoDeals[5]})}
                </div>
            </div>
    )
}


const formatAndFilterDeals = (results) => {
    // clone
    let new_results = results.filter(a => a.location === 'hanoi').map(a => {return {...a}})

    for (let i = new_results.length - 1; i >= 0; i--){
        
        let dt = new Date(Date.parse(new_results[i].expiry))
        // get minutes difference between current time and deal expiry time
        let diff = (dt.getTime() - new Date(Date.now())) / 1000 / 60
        new_results[i].diff = diff
    }
    new_results = new_results.sort((a, b) => a.diff < 0 ? a.diff < b.diff : a.diff > b.diff)
    // for (let i = new_results.length - 1; i >= 0; i--){
        
    //     if (typeof new_results[i] !== 'undefined' && typeof new_results[i-1] !== 'undefined' && compareStrings(new_results[i-1].title, new_results[i].title) > 50 ){
    //         // console.log('duplicate deal ', new_results[i-1].title, new_results[i].title)
    //         new_results.splice(i, 1)
    //         continue
    //     }
    // }
    
    return new_results
}

const HomeScreen = () => {

  const { t } = useTranslation();
  const [promoDeals, setPromoDeals] = React.useState([])

  const fetch_deals = async () => {
    
    const month = new Date().getMonth() + 1
    const year = new Date().getFullYear()
    let month_year =  year+'-'+month.toString().padStart(2, '0')
    
    let results = []
    let tmp_deals = []
    let res = null

    // load last 2 months of data from await axios.get('/deals/deals_' + month_year + '.json')
    console.log('loading ... ', month_year)
    try{
        res = await axios.get('/deals/deals_' + month_year + '.json')
        tmp_deals = formatAndFilterDeals(res.data)
    }
    catch(e){
        console.log('no deals this month yet?')        
    }
    let tmp_month = month
    let tmp_year = year
    for (let i = 0; i < 3; i++){
        tmp_month = tmp_month - 1

        if (tmp_month === 0){
            tmp_month = 12
            tmp_year = tmp_year - 1
        }
        // month = (month - 1).toString().padStart(2, '0')
        month_year =  tmp_year+'-'+tmp_month.toString().padStart(2, '0')
        
        try{
            res = await axios.get('/deals/deals_' + month_year + '.json')
            results = formatAndFilterDeals(res.data)
            
            tmp_deals = [...tmp_deals, ...results]
        }
        catch(e){
            console.log('no deals this month yet?')        
        }
    }

    let businesses = []
    let promo_deals = [] 
    
    for (let i=0; i < tmp_deals.length; i++){
        if (businesses.includes(tmp_deals[i]['restaurant_id'])){
            continue
        }
        businesses.push(tmp_deals[i]['restaurant_id'])
        promo_deals.push(tmp_deals[i])
        if (promo_deals.length > 8){
            break
        }
        
    }
    
    while (promo_deals.length < 6){
        promo_deals.push(tmp_deals[-1*promo_deals.length])
    }

    setPromoDeals(promo_deals)

  }

  React.useEffect(() => {
    
    // load video 
    var script = document.createElement("script")
    script.setAttribute("id", 'video')
    script.setAttribute("src", 'js/video_header.js');
    

    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }

    fetch_deals().catch(e => console.log(e))
    

  }, [])


  return (
    <Layout showDownload={true} showPreloader={true}>
        
        <section className="header-video">
            <div id="hero_video">
            <div id="sub_content">
                <h1>{t('homepage.header', 'Rescue Food - Save Money - Support Local')}</h1>
                <p>
                {t('homepage.sub_header', 'Sustainable Dine In, Take Away and Delivery App.')}
                </p>
            </div>
            </div>
            <img
            src="/img/video_fix.png"
            alt=""
            className="header-video--media"
            data-video-src=""
            data-teaser-source="video/intro1"
            data-provider=""
            data-video-width={1920}
            data-video-height={960}
            />
            <div id="count" className="d-none d-md-block">
            <ul>
                <li>
                <span className="number">70</span> {t('homepage.restaurant', 'Restaurant')}
                </li>
                <li>
                <span className="number">1030</span> {t('homepage.meals_saved', 'Meals Saved')}
                </li>
                <li>
                <span className="number">1567</span> {t('homepage.app_users', 'App Users')}
                </li>
            </ul>
            </div>
        </section>
        <div className="container margin_60">
            <div className="main_title">
            <h2 className="nomargin_top">{t('homepage.how_it_works', 'How it works')}</h2>
            <p>{t('homepage.where_food_comes_from', 'Where does the food come from?')}</p>
            </div>
            <div className="row">
            <div className="col-lg-3 col-md-6">
                <div className="box_home" id="one">
                <span>1</span>
                <h3>{t('homepage.how_it_works_title_1', 'Restaurants identify food surplus')}</h3>
                <p>{t('homepage.how_it_works_desc_1', 'This is done randomly throughout the day')}</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="box_home" id="two">
                <span>2</span>
                <h3>{t('homepage.how_it_works_title_2', 'They create Flash Deal Offers')}</h3>
                <p>{t('homepage.how_it_works_desc_2', 'These offers get sent out to Baobab Deals users in the area')}</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="box_home" id="three">
                <span>3</span>
                <h3>{t('homepage.how_it_works_title_3', 'App Users Claim the Offers')}</h3>
                <p>{t('homepage.how_it_works_desc_3', 'Deals are subject to different terms and conditions')}</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="box_home" id="four">
                <span>4</span>
                <h3>{t('homepage.how_it_works_title_4', 'Delivery, Dine In or Take Away?')}</h3>
                <p>{t('homepage.how_it_works_desc_4', 'Restaurants call users to confirm and arrange')}</p>
                </div>
            </div>
            </div>
            <div id="delivery_time" className="d-none d-sm-block">
            <strong>
                <span>3</span>
                <span>7</span>
                <span>%</span>
            </strong>
            <h4>{t('homepage.avg_discount', 'The average discount on Baobab Deals')}</h4>
            </div>
        </div>
        <div className="white_bg">
            <div className="container margin_60">
                <div className="main_title">
                    <h2 className="nomargin_top">{t('homepage.last_deals', 'Featured Deals')}</h2>
                    <p style={{ paddingTop: '10px'}}>{t('homepage.see_all_deals', "See what's been happening on our app in the last few hours")}, <a href='/deals'>all deals</a>
                    </p>
                </div>
                <Deal promoDeals={promoDeals} />
            </div>
        </div>
        <div className="high_light">
            <div className="container">
            <h3>{t('homepage.choose_from_over', 'Choose from over 70 sustainable partners')}</h3>
            <p style={{ paddingTop: '6px' }}>{t('homepage.we_are_adding_partners', 'We are constantly adding more partnerships, watch us grow!')}</p>
            <a href="/partners">{t('homepage.view_all_partners', 'View All Partners')}</a>
            </div>
        </div>
        <section
            style={{ 
                backgroundImage: 'url(/img/serverless.jpg)', 
                backgroundSize: 'cover', 
                backgroundPosition: 'center center', 
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'scroll', 
                marginTop: '0px'
             }}
             className="serveless"
            // className="parallax-window"
            // data-parallax="scroll"
            // data-image-src="/img/bg_office.jpg"
            // data-natural-width={1200}
            // data-natural-height={600}
        >
            <div className="parallax-content">
            <div className="sub_content">
                <i className="icon-cog-alt" />
                <h3>{t('homepage.tech_title', 'We tap into sustainable cloud computing resources')}</h3>
                <p>{t('homepage.tech_desc', 'Our app is powered by excess cloud computing resources making us ~80% more energy efficient.')}</p>
            </div>
            </div>
        </section>
        <div className="container margin_60">
            <div className="main_title margin_mobile">
            <h2 className="nomargin_top">{t('homepage.work_title', 'Work With Us!')}</h2>
            <p style={{ paddingTop: '6px'}}>{t('homepage.work_desc', 'Join us in the fight for a more sustainable future.')}</p>
            </div>
            <div className="row justify-content-center">
            <div className="col-md-5">
                <a className="box_work" href="/download-business">
                <img
                    src="/img/home-join-business.png"
                    width={848}
                    height={480}
                    alt=""
                    className="img-fluid"
                />
                <h3>
                    {t('homepage.add_business', 'Add your Business')} <span>{t('homepage.meet_customers', 'Meet new customers!')}</span>
                </h3>
                <p style={{ paddingTop: '6px'}}>{t('homepage.add_business_desc', 'Join Baobab Business App to start posting deals within the next 24 hours. Introduce your business to new customers and improve profitability.')}</p>
                <div className="btn_1">{t('homepage.business_app', 'Business App')}</div>
                </a>
            </div>
            <div className="col-md-5">
                <a className="box_work" href="/contact">
                <img
                    src="/img/home-join-team.png"
                    width={848}
                    height={480}
                    alt=""
                    className="img-fluid"
                />
                <h3>
                {t('homepage.join_team', 'Join Our Team')}<span>{t('homepage.became_hero', 'Become a Hero')}</span>
                </h3>
                <p style={{ paddingTop: '6px'}}>{t('homepage.join_team_desc', "We'e looking to expand our partnerships and grow our team. If you like the project and want to get involved, get in touch with us.")}</p>
                <div className="btn_1">{t('homepage.contact_us', 'Contact Us')}</div>
                </a>
            </div>
            </div>
        </div>
    </Layout>
  )
}

export default HomeScreen