import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow, MarkerLabel } from 'google-maps-react';


const _mapLoaded = (mapProps, map) => {
    map.setOptions({
        styles: mapStyle
    })
}

const API_KEY = "AIzaSyCR1WOkIl5fzI-DPpE9F7uK4EMDkh2Nt2g"

const GoogleMaps2 = ({ google, marker }) => {
    
    const [map, setMap] = React.useState(null);
    const [infoWindowOpen, setInfoWindowOpen] = React.useState(false);
  
    React.useEffect(() => {
      if (map) {
        setInfoWindowOpen(true);
      }
    }, [map]);
  
    const handleMapLoad = (map) => {
      setMap(map);
    };

    if (!marker || !marker.lng || !google){
        return null
    }
    return (
      <Map
        google={google}
        zoom={16}
        style={mapStyles}
        onReady={(mapProps, map) => {_mapLoaded(mapProps, map); handleMapLoad(map)} }
        initialCenter={ {lat: marker.lat, lng: marker.lng} }
    
      >
        <Marker 
            key={marker.id} 
            position={{ lat: marker.lat, lng: marker.lng}} 
            icon={ { url: "/img/pin-red.png", scaledSize: { width: 36, height: 48 } }}             
        />
        {map && (
            <InfoWindow
                position={{ lat: marker.lat, lng: marker.lng }}
                visible={infoWindowOpen}
                onCloseClick={() => setInfoWindowOpen(false)}
            >
            <div style={{ padding: '10px' }}>
                <h5>{marker.name}</h5>
                <p style={{ color: 'rgb(85, 85, 85)'}}>{marker.desc}</p>
            </div>
            </InfoWindow>
        )}        
          
      </Map>
  );
};


// const API_KEY = "AIzaSyCR1WOkIl5fzI-DPpE9F7uK4EMDkh2Nt2g"
const GoogleMapsSingleMarker = GoogleApiWrapper({
  apiKey: API_KEY
})(GoogleMaps2);

const mapStyles = {
  width: '100%',
  height: '100%'
};




const mapStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ebe3cd"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#523735"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f1e6"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#c9b2a6"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#dcd2be"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#ae9e90"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#93817c"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#a5b076"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#447530"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f1e6"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#fdfcf8"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f8c967"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#e9bc62"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e98d58"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#db8555"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#806b63"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8f7d77"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ebe3cd"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#b9d3c2"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#92998d"
        }
      ]
    },
    {
      featureType: 'poi.business',
      stylers: [{visibility: 'off'}]
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}]
    }
  ]


export default GoogleMapsSingleMarker