import { useTranslation } from 'react-i18next';



const formatTimeLeft = (minutes, t) => {

    if (minutes > 0 && minutes < 60) {
        return Math.floor(minutes) + t('deal.min_left', 'min left')
    }
    if (minutes > 60 && minutes < 120) {
        return '1h ' + t('deal.left', 'left')
    }
    if (minutes > 120 && minutes < 180) {
        return '2h ' + t('deal.left', 'left')
    }
    if (minutes > 180 && minutes < 240) {
        return '3h ' + t('deal.left', 'left')
    }
    if (minutes > 240 && minutes < 300) {
        return '4h ' + t('deal.left', 'left')
    }
    if (minutes > 300) {
        return '4h ' + t('deal.left', 'left')
    }
    if (minutes <= 0 && minutes > -60) {
        return Math.floor(minutes) + t('deal.min_ago', 'min ago')
    }
    if (minutes <= -60 && minutes > -120) {
        return '1h ' + t('deal.ago', 'ago')
    }
    if (minutes <= -120 && minutes > -60*12) {
        return t('deal.ended_hours_ago', 'few hours ago')
    }
    if (minutes <= -60*12 && minutes > -60*36) {
        return t('deal.ended_yesterday', 'ended yesterday')
    }

    return t('deal.ended', 'ended')

}

    
    
const DealBox = ({deal, extended}) =>  {

    const { t } = useTranslation();

    if (!deal) {
        return null
    }
    
    const deal_url = '/deal/' + deal.id
    const class_name = extended ? 'strip_list wow fadeIn' : 'strip_list'
    const style = extended ? { height: '300px', paddingTop: '20px' } : {}
    return (
    <a href={deal_url} style={style} className={class_name} data-wow-delay="0.1s">
        
        <div className="desc" style={{ paddingLeft: '145px'}}>
            <div className="thumb_strip_deal">
                <img src={deal && deal.image_url} alt="" />
            </div>
            <h4 style={{ marginTop: '0px', marginBottom: '0px', paddingTop: '2px'}}>{deal &&  deal.title}</h4>
            <span className={ deal.diff > 0 ? "active_deal deal_time_left" : "deal_time_left"} style={{ float: 'right', position: 'relative', bottom: '25px', fontWeight: '400'}}>&nbsp;{deal &&  formatTimeLeft(deal.diff, t)}</span>
            
            { extended && <div className="type">{deal &&  deal.description}</div>}
            { !extended && <div className="type">{deal &&  deal.business_name}</div>}

            <div style={{ marginBottom: '4px', marginTop: '2px'}}>
                {t('deal.price', 'price')}: <span style={{ fontWeight: '900', fontSize: '14pxs'}}>{deal &&  deal.discounted_price}K VND</span>
            </div>
            <div style={{ marginBottom: '4px', marginTop: '2px'}}>
                {t('deal.retail', 'retail')}: <span style={{ textDecoration: 'line-through'}}>{deal &&  deal.price}K VND</span> <span style={{ color: '#C33' }}>&nbsp;{deal &&  deal.discounted_perc}% {t('deal.off', 'off')}</span>
            </div>

        </div>
    </a>        
)
}
export default DealBox