import React from "react";
import {
  useParams
} from "react-router-dom";


import Layout from "../components/Layout";



const TermsScreen = () => {

  React.useEffect(() => {
  
    
    let script = document.createElement("script")
    script.setAttribute("id", 'header_on')
    script.setAttribute("src", '/js/baobab/header_on.js');
    
    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }

  }, [])


  return (
    <Layout showDownload={false}>
      <div className="container terms">
          <div className="row">
              <div className="col-md-12" style={{ paddingTop: '80px', paddingBottom: '40px'}}>
                <h1>Baobab Deals Application</h1>
                <h2>Users Terms of Service</h2>

                <p>Last updated: December 7, 2021</p>

                <p>
                  This Agreement explains the terms and conditions through which Baobab App allows mobile Application Users the ability to utilize the Application. Please note, Baobab App is not responsible or liable for the actions of any third parties actions whether confirmed or assumed. Baobab does not have any form of control over Vendors or Application Users or their actions, and as a result, is not liable for these. 
                </p>


                <h1>1. Services Explained</h1>
                <h2>1.1 This Agreement </h2>
                <p>This is an agreement (the “User Terms of Service” ) between you ( the “User” ) and Baobab.deals (the “App”) an application of Behobe Tech Limited. By downloading and using the App you agree to these User Terms of Service and are bound by them, so if you do not wish to be bound do not download the App or use it. Please note the Terms of Service for Users agreement is valid for the duration of the period that the App services are utilized. In addition, the agreement will end should Baobab choose to cancel your service and terminate your account. You may also send an email at any stage to info@baobab.deals to delete your account. </p>


                <h2>1.2 What Baobab App offers</h2>
                <p>The Baobab App is an application that provides Users and Vendors (restaurants, bars, bakeries and supermarkets) a platform to connect. The App allows them to provide Users a chance to claim and collect food that is of surplus or of near expiry at a discounted rate. The User then goes into the stores and collects the food ordered as a Take-Away option or dine-in depending on preferences. All transactions that occur, do so strictly between the User and the Vendor on location and not over the App. In short, this App is a tool that helps you locate flash deals near you, so that you can claim the food before it’s no longer wanted. </p>

                <h1>2. How it works</h1>
                <h2>2.1 Map or List View</h2>  
                <p>Access the current deals in your area either directly through the map screen by clicking on a restaurant logo or by clicking on an active deal in the list screen. Both Screens are available through a tab on the bottom of the screen view. Maps can be used as a way to find restaurants near you to take advantage of a deal or simply as a directory to find one of your favorite restaurants contact details and address. We provide all of our Vendors information straight to the user because we want you to contact them directly too. We’re cool like that!</p>
                <h2>2.2 Claim an Active Deal </h2>
                <p>Active deals are shown on the app through the App either by a large logo on the map or a green indicator on the list view. This saves you from having to scroll through endless items that are irrelevant to you. </p>
                <p>Claim a deal and decide whether or not you want to: </p>
                <h3>2.2.1) Take-away: </h3>
                <p>Go past the restaurant before the deal expires and pick it up. You have the option here to bring along your own containers and reduce the amount of plastic waste created. The Vendors will list one of two options 1) Till last = until stock runs out, 2) Limited = a confirmed number of items available. These items are sold on the basis of first come first serve. You may also dine in at the Vendors premises should they have tables available and permit the deal in house. </p>
                <h3>2.2.2) Delivery: </h3>
                <p>We do not provide any delivery services. Users are able to contact the Vendors for arrangements outside the scope of the App, but we will not be responsible for any issues resulting from such agreements between the User and Vendor directly. </p>

                <h3>2.2.3) Alcohol: </h3>
                <p>Please note that this is done at the Vendors discretion and is subject to all the laws in effect within a region of operation. This responsibility rests with the Vendors and Vendors have the right to refuse service for any reason that they deem reasonable. </p>
                <h2>2.3 Collecting your Claimed Deals: </h2>
                <p>Users are responsible for making their order number confirmable by the Vendor on arrival. This could also be done by providing the Vendor with your phone number for confirmation. Once a deal is collected a User is obliged to complete the deal claim process by clicking on the “Collected” button in the App. </p>
                <h2>2.4 Ratings and Comments: </h2>
                <p>Users are prohibited from posting illegal, unethical or discriminatory comments about Vendors. Users are responsible for the content that they enter into the application. </p>
                <h2>2.5 Problems with Claiming your Deal: </h2>
                <p>Baobab is a service provider to both the User and the Vendors. We do make, produce or directly supply the items on the platform. Vendors are solely responsible for the content that is uploaded, and the quality of the items made available for claim to the User. All questions are queries about the items available should be directed to the Vendors through the contact details provided. All Vendors are required to comply with quality and standards as set out within their respective regions of operation. If you have an issue with a Vendor or wish to report an issue, please contact us at info@baobab.deals we will investigate and revert to you as soon as possible. </p>

                <h2>2.6 Finalize your account: </h2>
                <p>Enter your name and phone number to complete your claim process. This will be used to complete your registration process and you will be unable to claim anything without this information. It is your responsibility to ensure that the information is correct and accurate. Failure to provide a valid number and contact details will result in suspension of the App services. Should you have an issue contact us at info@baobab.deals . </p>
                <h1>3. App Content </h1>
                <p>The App will have content that is generated by Vendors and Third Parties. It is the sole responsibility of the Vendor and Third Parties to provide content that is true and correct. Baobab is in no way responsible for the content that these entities generate on the App. Baobab will in no way be held responsible or liable for any content generated and added to the App or damages incurred due to this content. Vendors and Users are responsible for their interactions with the app and are encouraged to use this App with responsibility and within reason. </p>
                <h2>3.1) Deal content </h2>
                <p>The services and offerings that are provided are done so on an “as is” basis and “as available”. </p>
                <h1>4. App Changes </h1>
                <p>Baobab is permitted to change and update the Terms of Service for Users occasionally. To do this we will provide an update of these Terms of Service to Users on the website. If there are significant or impactful changes anticipated a prior notice will be sent out to all Users through push notification. </p>

                <h1>5. Miscellaneous: </h1>
                <p>Baobab reserves the right to transfer these services to a third party at any time as deemed suitable. </p>
                <p>All disputes or queries relating to this agreement should be directed to info@baobab.deals . We will get in touch as soon as possible. </p>
                <p>In the case of an unforeseeable event or force majeure Baobab App will not be help responsible for the delay in or inability to provide any services as set out in the App or this agreement. </p>
                <p>Baobab retains the right to delete, terminate, discontinue and suspend all services and websites at any stage that we deem suitable. </p>
                <h1>6. Ownership: </h1>
                <p>Baobab App is the sole owner of the software, the App, application services and source code. You may not use any of the content or materials from the website or application without the express written consent of the Baobab App.  </p>
                <h1>7. Our Address: </h1>
                <p>BEHOBE TECH LIMITED, </p>
                <p>UNIT 1008 10/F PROSPERITY MILLENIA PLAZA 663 KINGS ROAD, QUARRY BAY, HONG KONG</p>

              </div>
        </div>
      </div>

    </Layout>
  
    )
}

export default TermsScreen;