import React from "react";
import { useTranslation } from 'react-i18next';

import Layout from "../components/Layout";



const AboutUsScreen = () => {

  const { t } = useTranslation();


  React.useEffect(() => {
  
    
    let script = document.createElement("script")
    script.setAttribute("id", 'about')
    script.setAttribute("src", '/js/baobab/about.js');
    
    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }

  }, [])


  return (
    <Layout showDownload={true} >
  {/* SubHeader =============================================== */}
  <section id="short" style={{ backgroundImage: "url('img/about-header.png')", backgroundSize: 'cover' }} >
   
    <div id="subheader">
      <div id="sub_content">
        <h1>{t('about.title', 'About us')}</h1>
        <p>{t('about.sub_title', 'Promoting sustainability with every bite.')}</p>
        <p />
      </div>
      {/* End sub_content */}
    </div>
    {/* End subheader */}
  </section>
  {/* End section */}
  {/* End SubHeader ============================================ */}
  

  {/* Content ================================================== */}
  <div className="container margin_60_35">
    <div className="row justify-content-between">
      <div className="col-xl-5 col-lg-5">
        <h3 className="nomargin_top">{t('about.title', 'About us')}</h3>
        <p>{t('about.desc_1', "At Baobab Deals, we believe that food should be good for you and good for the planet. That's why we're committed to reducing our environmental impact and supporting our community through responsible sourcing and waste reduction practices. With Baobab Deals, you can feel good about the food you eat and the choices you make.")}</p>
        <p>{t('about.desc_2', 'Join us on our journey to create a brighter, more sustainable food future. Download the Baobab Deals app today and start eating well, for yourself and for the planet.')}</p>
        <h4 style={{ paddingTop: '15px'}}>{t('about.our_mission', 'Our Mission')}</h4>
        <p>
        {t('about.desc_3', 'At Baobab Deals, we’re on a mission to make sustainable eating the norm for the next generations. We believe that everyone has the power to make a difference through the choices they make, and we’re committed to making it easy for our customers to choose food that’s good for them and good for the planet. By partnering with local restaurants, supermarkets, bakeries and using cutting-edge technology, we’re creating a food future that’s bright, bold, and full of flavor.')}
        </p>
        <h4 style={{ paddingTop: '15px'}}>{t('about.philosophy', 'Philosophy')}</h4>
        <p>
        {t('about.desc_4', 'Overall, the philosophy of Baobab Deals is one of responsibility, sustainability, and innovation. By combining these values with a commitment to providing high-quality, healthy food options, Baobab Deals is working to create a brighter, more sustainable food future for everyone.')}
        </p>
      </div>
      <div className="col-xl-7 col-lg-7 text-right d-none d-lg-block">
        <img src="/img/devices.png" alt="" className="img-fluid" />
      </div>
    </div>
    {/* End row */}
    <hr className="more_margin" />
    <div className="main_title">
      <h2 className="nomargin_top" style={{ paddingBottom: '10px'}}>{t('about.what_is_the_deal', "What's the Deal at Baobab?")}</h2>
      <p>{t('about.faq', "Frequently Asked Questions")}</p>
    </div>
    <div className="row">
      <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
        <div className="feature">
          <i className="icon_building" />
          <h3>
            <span>{t('about.where', "Where")}</span> {t('about.box1', "do deals come from?")}
          </h3>
          <p>
          {t('about.box1_desc', "Restaurants who wish to reduce the amount of food they waste connect with the app. The food is the same quality you would normally get, but it's at the end of the accepted shelf life and needs to get sold ASAP to avoid heading to the bins.")}
          </p>
        </div>
      </div>
      <div className="col-md-6 wow fadeIn" data-wow-delay="0.2s">
        <div className="feature">
          <i className="icon_documents_alt" />
          <h3>
          {t('about.box2', "Area of Operations?")} <span>{t('about.hanoi', "Hanoi")}</span>
          </h3>
          <p>
          {t('about.box2_desc', "At the moment Baobab Deals is operating in Hanoi, Vietnam. However we are on a mission to bring our concept to other cities across Vietnam and are currently creating partnerships to do so. Reach and get involved, if your business would be a fit.")}
          </p>
        </div>
      </div>
    </div>
    {/* End row */}
    <div className="row">
      <div className="col-md-6 wow fadeIn" data-wow-delay="0.3s">
        <div className="feature">
          <i className="icon_bag_alt" />
          <h3>
          {t('about.box3', "Delivery | Take Away |")} <span>{t('about.dine_in', "Dine In")}</span>
          </h3>
          <p>
          {t('about.box3_desc', "Through our app you claim deals and the restaurant gets in touch with you to facilitate the deal is completed. Some deals are exclusive to dine in's and others are available through all three options. Check the description and wait for the restaurant to contact you.")}
          </p>
        </div>
      </div>
      <div className="col-md-6 wow fadeIn" data-wow-delay="0.4s">
        <div className="feature">
          <i className="icon_mobile" />
          <h3>
          {t('about.box4_1', "What")} <span>{t('about.devices', "devices")}</span> {t('about.box4_2', "are supported?")}
          </h3>
          <p>
          {t('about.box4_desc', "Both applications for consumer and business are supported by IOS and Android. This is likely to be expanded in the future as demand grows for us to support other devices and software. We are workign towards making the app available to everyone.")}  
          </p>
        </div>
      </div>
    </div>
    {/* End row */}
    <div className="row">
      <div className="col-md-6 wow fadeIn" data-wow-delay="0.5s">
        <div className="feature">
          <i className="icon_wallet" />
          <h3>
            <span>{t('about.cash', "Cash")}</span> {t('about.box5', "payment")}
          </h3>
          <p>
          {t('about.box5_desc', "We don't get involved in the monetary transaction at all. This is done completely between the restaurant and the customer. We are working on payment options and will include in app payments and transactions in the future for convenience.")}
          </p>
        </div>
      </div>
      <div className="col-md-6 wow fadeIn" data-wow-delay="0.6s">
        <div className="feature">
          <i className="icon_creditcard" />
          <h3>
          {t('about.box6', "Claim")} <span>{t('about.processing', "processing")} </span>
          </h3>
          <p>
          {t('about.box6_desc', "Once you claim a deal and the restaurant gets in touch to facilitate the process is completed. Some deals are exclusive to dine in's and others available through all three options. Check the description and wait for the restaurant to contact you.")}
          </p>
        </div>
      </div>
    </div>
    {/* End row */}
  </div>
  {/* End container */}
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-6 nopadding features-intro-img">
        <div className="features-bg">
          <div className="features-bg-img">
          <div className="features-img"></div>
            
          </div>
        </div>
      </div>
      <div className="col-lg-6 nopadding">
        <div className="features-content">
          <h3>{t('about.unep', "UNEP Sustainable Development Goals")}</h3>
          <p>
          {t('about.unep_desc', "SDG 12 recognizes that long-term development and economic growth depend on changing how we produce and consume goods. It demands more efficient and environmentally friendly management of materials across the lifecycle, through production, consumption, and disposal.")}
          </p>
        </div>
      </div>
    </div>
  </div>
  {/* End container-fluid  */}
  {/* End Content =============================================== */}

    </Layout>
  
    )
}

export default AboutUsScreen