import React from "react";
import axios from 'axios';

import Layout from "../components/Layout";
import GoogleMaps from "../components/GoogleMaps";

import { useTranslation } from 'react-i18next';



const Partner = ({business, active, setActive}) => {
  return  (
    <a className="strip_list" onClick={() => setActive(business.id)} style={{ cursor: 'pointer'}}>
      <div className="row">
        <div className="col-md-10">
          <div className="desc">
            <div className="thumb_strip_3">
                <img src={business.logo} style={{ transform: "scale(1)"}} alt="" />
            </div>
            <h4 style={ active === business.id ? {color: "#e04f67"} : {}}>{business.name}</h4>
            <div className="type">
              {business.address}
            </div>
            <div className="location">{business.description}</div>
          </div>
        </div>
      </div>
      {/* End row*/}
    </a>
  )
}

const PartnersScreen = () => {

  const { t } = useTranslation();

  const [businesses, setBusinesses] = React.useState([])
  const [active, setActive] = React.useState(null)
  const [filtered, setFiltered] = React.useState([])
  const [mapCenter, setMapCenter] = React.useState(null)
  const [dealsCount, setDealsCount] = React.useState()

  React.useEffect(() => {
  
    const fetch_businesses = async () => {
      let res = await axios.get('/deals/businesses.json')
      setBusinesses(res.data)
      
    }

    // load scripts 
    var script = document.createElement("script")
    script.setAttribute("id", 'video2')
    script.setAttribute("src", 'js/map_listing.js');
    
    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }
    
    fetch_businesses()

    // get deals count 
    const month = new Date().getMonth() 
    const year = new Date().getFullYear() 
    let month_year =  year+'-'+month.toString().padStart(2, '0')
    
    axios.get('/deals/deals_' + month_year + '.json')
    .then((res) => {
      console.log('deals count ', month_year, res.data.length)
      setDealsCount(res.data.length)
    })
    .catch(e =>console.log(e))

  }, [])

  React.useEffect(() => {
    setFiltered(businesses)
  }, [businesses])

  React.useEffect(() => {
    if (!active){
      return
    }
    let business = businesses.find((b) => b.id === active)
    setMapCenter({lat: business.latitude, lng: business.longitude})

  }, [active])

  const onFilter = (e) => {
    let value = e.target.value
    let filtered = businesses.filter((b) => b.name.toLowerCase().includes(value.toLowerCase()))
    setActive(null)
    setFiltered(filtered)
  }


  return (
    <Layout showDownload={true} >
    {/* SubHeader =============================================== */}
    <section id="short" style={{ backgroundImage: "url('img/hanoi.jpg')", backgroundSize: 'cover' }} >
    
      <div id="subheader">
        <div id="sub_content">
          { businesses && businesses.length &&
            <h1><span className="number">{businesses.length}</span> {t('partners.active_count', 'Active Partners in Hanoi')}</h1>          
          }
          { dealsCount &&
          <div>
            <i className="icon-calendar" /> <span className="number">{dealsCount}</span> {t('partners.deals_count', 'deals last month')}
          </div>
          }
        </div>
        {/* End sub_content */}
      </div>
      {/* End subheader */}
    </section>
    {/* End section */}

    <div className="container-fluid full-height">
      <div className="row row-height">
        <div className="col-lg-5 map-right" style={{marginTop: '0px'}}>
          <GoogleMaps 
            markers={filtered.map(b => { return {id: b.id, name: b.name, desc: b.address, lat: b.latitude, lng: b.longitude }})} 
            mapCenter={mapCenter}
            active={active}
            markerCallback={(id) => {setActive(id)}}
          />
        </div>
        <div className="col-lg-7 content-left business-list" style={{padding: '20px 8px', marginTop: '0px' }}>

        <div className="form-group" style={{ padding: '0px'}}>
            <input 
              type="text" 
              className="form-control" 
              placeholder="&#x1F50D;" 
              style={{filter: "grayscale(100%)"}} 
              onChange={onFilter} 
            />
        </div>
          
          {filtered && filtered.filter((b) => b.location === 'hanoi').sort((a, b) => a.name > b.name).map((business, index) => (
            <Partner business={business} key={business.id} active={active} setActive={setActive}/>
          ))}
 
        </div>
        {/* End content-left*/}
        
      </div>
      {/* End row*/}
    </div>
    {/* End container-fluid */}
    
  </Layout>
  
    )
}

export default PartnersScreen