import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import './App.css';

import HomeScreen from './screens/HomeScreen'
import PartnersScreen from './screens/PartnersScreen'
import DealsScreen from './screens/DealsScreen'
import DealScreen from './screens/DealScreen'
import PartnerScreen from './screens/PartnerScreen'
import AboutUsScreen from './screens/AboutUsScreen'
import ContactScreen from './screens/ContactScreen'
import DownloadScreen from './screens/DownloadScreen'
import DownloadBusinessScreen from './screens/DownloadBusinessScreen'
import PrivacyScreen from './screens/PrivacyScreen'
import TermsBusinessScreen from './screens/TermsBusinessScreen'
import TermsScreen from './screens/TermsScreen'

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/partners",
    element: <PartnersScreen />,
  },
  {
    path: "/terms",
    element: <TermsScreen />,
  },
  {
    path: "/terms-business",
    element: <TermsBusinessScreen />,
  },
  {
    path: "/privacy",
    element: <PrivacyScreen />,
  },
  {
    path: "/partner/:id",
    element: <PartnerScreen />,
  },
  {
    path: "/deals",
    element: <DealsScreen />,
  },
  {
    path: "/deal/:id",
    element: <DealScreen />,
  },
  {
    path: "/deal*",
    element: <DealScreen />,
  },
  {
    path: "/about-us",
    element: <AboutUsScreen />,
  },
  {
    path: "/contact",
    element: <ContactScreen />,
  },
  {
    path: "/download",
    element: <DownloadScreen />,
  },
  {
    path: "/download-business",
    element: <DownloadBusinessScreen />,
  },
  
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} className="App"/>
  </React.StrictMode>
);


// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
