import { useTranslation } from 'react-i18next';



const Footer = () => {
    const { t } = useTranslation();
    
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6" style={{ paddingTop: '10px', paddingLeft: '0px', textAlign: 'center', paddingBottom: '40px', minWidth: '360px'}}>
                        <span style={{ borderRight: '1px solid', borderColor: '#ededed', padding: '2px 12px'}}>
                        <a href="/contact">{t('header.contact', 'Contact')}</a>
                        </span>
                        <span style={{ borderRight: '1px solid', borderColor: '#ededed', padding: '2px 12px'}}>
                        <a href="/privacy">{t('footer.privacy', 'Privacy')}</a>
                        </span>
                        <span style={{ borderRight: '1px solid', borderColor: '#ededed', padding: '2px 12px'}}>
                        <a href="/terms">{t('footer.terms', 'T&C')}</a>
                        </span>
                        <span style={{ padding: '2px 12px'}}>
                        <a href="/terms-business">{t('footer.terms_business', 'T&C Business')}</a>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div id="social_footer">
                            <ul>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/baobab.deals">
                                <i className="icon-facebook" />
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/baobab.deals">
                                <i className="icon-instagram" />
                                </a>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;