import React from "react";
import { useTranslation } from 'react-i18next';


import Layout from "../components/Layout";



const ContactScreen = () => {

  const { t } = useTranslation();


  React.useEffect(() => {
  
    
    let script = document.createElement("script")
    script.setAttribute("id", 'about')
    script.setAttribute("src", '/js/baobab/about.js');
    
    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }

  }, [])


  return (
    <Layout showDownload={true}>
  {/* SubHeader =============================================== */}
  <section id="short" style={{ backgroundImage: "url('img/contact-header.png')", backgroundSize: 'cover' }} >
    <div id="subheader">
      <div id="sub_content">
        <h1>{t('contact.title', "Contact Us")}</h1>
        <p>{t('contact.sub_title', "We're all in this together and many hands make for light work.")}</p>
        <p />
      </div>
      {/* End sub_content */}
    </div>
    {/* End subheader */}
  </section>
  {/* End section */}
  {/* End SubHeader ============================================ */}
  

  {/* Content ================================================== */}
  <div className="container margin_60_35">
      {/* Content ================================================== */}
  <div className="container margin_60_35">
    <div className="row" id="contacts">
      <div className="col-md-6">
        <div className="box_style_2">
          <h2 className="inner" style={{ color: '#333', marginTop: '10px', paddingLeft: '25px'}}>{t('contact.service', "Customer Service")}</h2>
          <p className="add_bottom_30">
          {t('contact.service_desc', "We use all your feedback to get better and improve our products and services. After all, we can't save the world on our own.")}
          </p>
          <p className="nopadding">
            <a href="mailto:info@baobab.deals">
              <i className="icon-mail-3" /> info@baobab.deals
            </a>
          </p>
        </div>
      </div>
      <div className="col-md-6">
        <div className="box_style_2">
          <h2 className="inner" style={{ color: '#333', marginTop: '10px', paddingLeft: '25px'}}>{t('contact.partner', "Partner Support")}</h2>
          <p className="add_bottom_30">
          {t('contact.partner_desc', "For existing Baobab Business App partners and urgent issues please send an email to the address listed below. For more time sensitive issues call  for support.")}
          </p>
          <p className="nopadding">
            <a href="mailto:ashley@baobab.deals">
              <i className="icon-mail-3" /> ashley@baobab.deals
            </a>
          </p>
        </div>
      </div>
    </div>
    {/* End row */}
  </div>
  {/* End container */}
  {/* End Content =============================================== */}
  </div>
  {/* End container */}
  {/* End container-fluid  */}
  {/* End Content =============================================== */}

    </Layout>
  
    )
}

export default ContactScreen;