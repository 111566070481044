import React from "react";
import { useTranslation } from 'react-i18next';


import Layout from "../components/Layout";

const IOS_LINK = 'itms-apps://apps.apple.com/app/id1600433802'
const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=deals.baobab'

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}



const DownloadScreen = () => {

  const { t } = useTranslation();

  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
  
    
    let script = document.createElement("script")
    script.setAttribute("id", 'about')
    script.setAttribute("src", '/js/baobab/about.js');
    
    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }

    const os = getMobileOperatingSystem()
    let link = ''
    if (os == 'Android') {
      link = ANDROID_LINK
      setIsMobile(true)
    } 
    else if (os === 'iOS') {
      link = IOS_LINK
      setIsMobile(true)
    }
    
    setTimeout(() => {
      if (link.length > 0){
        window.location.href = link
      }
    }, 1000)

  }, [])


  return (
    <Layout stickyHeader={false} showDownload={false}>
  {/* SubHeader =============================================== */}
  <section id="short" style={{ backgroundImage: "url('img/header-download.jpg')", backgroundSize: 'cover' }} >
    <div id="subheader">
      <div id="sub_content">
        <h1>{t('download.title', 'Download Baobab App')}</h1>
        <p>{t('download.sub_title', 'Scan the QR code or follow the link on your mobile.')}</p>
        <p />
      </div>
      {/* End sub_content */}
    </div>
    {/* End subheader */}
  </section>
  {/* End section */}
  {/* End SubHeader ============================================ */}
  

  {/* Content ================================================== */}
  <div className="container margin_60_35">
      {/* Content ================================================== */}
  <div className="container margin_60_35">
    <div className="row" id="contacts">
      { !isMobile &&
      <div className="col-md-6">
        <div className="box_style_2 download-container">
          <h2 className="inner" style={{ color: '#333', marginTop: '40px', marginBottom: '0px'}}>{t('download.scan', 'Scan QR code with phone')}</h2>
          <img src='/img/qr-retail.png' style={{ width: "275px" }} alt="" />
          <p className="add_bottom_30" style={{ padding: '10px 50px'}}>
          {t('download.once_on_phone', 'Once on your phone, you will get automatically redirected to App Store / Play Store')}
          </p>
        </div>
      </div>
      }
      
      <div className="col-md-6">
      <div className="box_style_2 download-container">
          <h2 className="inner" style={{ color: '#333', marginTop: '40px', marginBottom: '0px'}}>{t('download.doesnt_work', "Doesn't work?")} </h2>
          <p className="add_bottom_30">
          {t('download.try', 'Try the direct links below')}
          </p>
          <p>
            <a href={IOS_LINK}>
              IOS App Store 
            </a>
          </p>
          <p>
            <a href={ANDROID_LINK}>
              Android Play Store 
            </a>
          </p>
          
        </div>
      </div>
    </div>
    {/* End row */}
  </div>
  {/* End container */}
  {/* End Content =============================================== */}
  </div>
  {/* End container */}
  {/* End container-fluid  */}
  {/* End Content =============================================== */}

    </Layout>
  
    )
}

export default DownloadScreen;