import React from "react";
import {
  useParams
} from "react-router-dom";


import Layout from "../components/Layout";



const TermsBusinessScreen = () => {

  React.useEffect(() => {
  
    
    let script = document.createElement("script")
    script.setAttribute("id", 'header_on')
    script.setAttribute("src", '/js/baobab/header_on.js');
    
    try {
      document.head.appendChild(script);
    } catch (e) {
      console.log(e)
    }

  }, [])


  return (
    <Layout showDownload={false}>
      <div className="container  terms">
          <div className="row">
              <div className="col-md-12" style={{ paddingTop: '80px', paddingBottom: '40px'}}>

                <h1>Baobab Vendor Application</h1>
                <h2>Vendors Terms of Service</h2>

                <p>Last updated: December 7, 2021</p>

                <p>
                  This Agreement explains the terms and conditions through which Baobab App allows mobile Application Users the ability to utilize the Application. Please note, Baobab App is not responsible or liable for the actions of any third parties actions whether confirmed or assumed. Baobab does not have any form of control over Vendors or Application Users or their actions, and as a result, is not liable for these. 
                </p>

                <h1>1. Services Explained</h1>
                <h2>1.1 What Baobab App offers</h2>
                <p>The Baobab App is an application that provides Users and Vendors a platform to connect. The Application allows Vendors to give Application Users a chance to claim and collect food that is of surplus or of near expiry at a discounted rate. The Application users then go into the stores and collect the food ordered as a Take-Away option or dine-in depending on vendors preferences. All transactions that occur, do so strictly between the app User and the Vendor on location and not over the application. In short, this application is a sales funnel via which a vendor can offer take-away food items and promotions to Application Users. </p>
                <p>Vendors are subject to the Vendors “Terms and Conditions” and are able to make the food available on the platform through the Vendors Application. </p>

                <h2>1.2 Introduction</h2>
                <p>The Terms and Conditions for User (hereinafter referred to as this “Agreement”), is a legally binding and contractual agreement between yourself (you the “user”) and BEHOBE TECH LIMITED, a Hong Kong based company (“Baobab App”), that provides you the ability to make use of and details proper and correct use of the Website (as defined below) and Application (as defined below).</p>
                <p>The act of downloading the Application and using the Application and/or Website is regarded as an acceptance and agreement to the binding terms contained within this Agreement.   </p>
                <p>Any failure to uphold the terms outlined in the agreement as attached will be regarded as a breach of your contractual obligation and give Baobab App the right to cancel all services and accounts connected to you or your user profile. Said cancellation may occur without notice and at any point that a breach of contract or policy occurs. </p>
                <p>If you do not wish to be bound by this contract please do not download or use the Application or the Website. This agreement is enforceable and binding. </p>
                <p>In the event that someone has taken on the responsibility of accepting this agreement on behalf of an entity they too will be fully responsible for the actions of the entity as per the terms established in this Agreement. </p>
                <p>For further information with regards to the processing and use of your data while interacting with this Application please go to http://baobab.deals/privacy to view our Privacy Policy. </p>

                <h1>2. Services Provided</h1>
                <h2>2.1 Registration</h2>
                <p>In order for a User to make use of the different services you will be required to create a vendor profile. This information may then be passed onto the app user in the baobab.deals app, so that a user might contact you to confirm the order and place the order. Only by completing this account registration and checking the checkbox to accept the terms and conditions you are permitted to make the order and complete the process. </p>
                <p>When you complete this account set up, the information that is required includes your telephone number, email address, password, business name, location and a pinned location on a map. This will give you a Vendor profile which allows you to manage the deals created. Please refer back to the Privacy Policy if you have any questions about how this information is stored and utilized. </p>

                <h2>3.2 Creating Deals </h2>
                <p>Baobab Vendor Application opens on the deals list page of the Application. On this page a Vendor can create deals, reschedule them or end them. These are indicated in list format and color coded for convenience. A Vendor then has the ability to create a new deal using the blank creation form. This form allows the Vendor to upload content from a media library on the smart device or to take a phot of said item and make it available to app users. The form is then completed as set out in the application. Vendors are completely responsible for the information provided regarding the items posted and must ensure that no local laws are infringed upon when creating new deals. </p>

                <h3>3.2.1 Personalized Push notifications:</h3>
                <p>Baobab Vendor application allows the Vendor the opportunity to send one (1) personalized push notification to users per day. This is done at the discretion of the Vendor and Vendors are responsible for the content that is made available to the user. Any abuse or misuse of this feature which is outside of the regular use of the application or in contravention of any local laws will be reasonable cause for Behobe Tech to suspend the account pending investigation without prior notice to the Vendors. </p>

                <h2>3.3 Information about the food items</h2>
                <p>At Baobab App we endeavor to provide the most up to date and accurate information to our Users. We are not responsible for the information used to represent the deals posted by Vendors as they are subject to providing accurate and truthful descriptions of the deals provided and made available. We do reproduce all the information that is supplied with regard to a deal in the deal: (i) name of deal, (ii) deal description, (iii) number of unit’s available, (iv) price of the deal, (v) time the deal will be available and the (vi) allergens in the food. The Vendors are each responsible for setting this information themselves and any information provided by the Vendor is the sole responsibility of the Vendor. Vendors are bound by terms of service to provide truthful and accurate information with regards to the deals provided. Any inaccuracies in the deals provided or the information regarding these deals is the sole responsibility of the Vendor. All users may directly contact a Vendor should they require any further information about a deal. </p>

                <h2>3.4 Deals Claimed</h2>
                <p>A deal is claimed if an app User has completed the registration process, hit the “claim” button and been directed to a screen that notifies the User of the order number, this will automatically generate a claim notification to the Vendor and provide the vendor with the Users phone number for convenience. At this stage a User may contact the restaurant directly to arrange delivery and payment or collect and pay for the order themselves in person and on location, completely independent of the Baobab Vendors App. Orders are confirmed as “collected” (completed) once a User has completed payment and taken delivery or made collection and hit the “collected” button. This will update the quantity of available items and update the Vendors listing. </p>

                <h2>3.5 Payments</h2>
                <p>No payments are made through the application and the User and Vendor will arrange for the payment as per the agreed upon/stated price in the User Application, as set out by the Vendor, subject to the deal conditions as set out in the Vendor Terms of Service. Baobab App does not at this stage deal with any payments or deal with any money originating from a User. Once an order has been placed the User may not cancel an order for any reason other than those reasons that are outlined in the User Application. Baobab Applications simply connect Users and Vendors and do not take any payment for services rendered. </p>

                <h2>3.6 Arrival at Vendor and Completion of collection</h2>
                <p>Once a User arrives at the Vendor to collect the deal, the User is required to give the vendor their order number or phone number, so that the Vendor can verify who has collected the deal. This verification is done through the order number and/or your phone number – which is shared with the restaurant (via push notification and in app). Users are required to complete confirmed orders and failure to notify a Vendor of inability to complete an order or failure to complete an order will put their profile under review. Baobab App retains the right to delete your account without notice or further explanation should your actions be deemed inconsistent with the above. If an order has been claimed by a User the Vendor is responsible to have the deal available for the user at the price and quantity as agreed upon / listed on the Vendors App. Baobab Applications are not responsible for the failure of a User to collect a deal in any capacity. We will endeavor to investigate and take the necessary actions to ensure User compliance in this regard. </p>
                <h2>3.7 Home delivery</h2>
                <p>In some cases, delivery can be arranged by contacting the Vendor directly. Baobab Applications do not offer this as a service and are not responsible for any losses that may occur between Vendors and the Users as a result of this service. This is an additional service provided in the Vendors own capacity. </p>
                <h2>3.8 Service Issues and Disputes</h2>
                <p>Vendors are completely responsible for the quality of all items made available to Users. Baobab Applications are not in anyway liable or responsible for the failure of a Vendor to comply with the services provided through the application. It is the Vendors sole responsibility to accurately represent the items/deals listed and issues related to quality or the collection of claimed deals are the responsibility of the vendor. Baobab Applications are not responsible for any errors or issues that occur during the collection of the deals and any issues that arise can be reported to info@baobab.deals . All Vendors are expected to operate within the predetermined guidelines and operation standards of their respective industries. Failure to comply will result in suspension of access to services without prior notice or warning, pending investigation.   </p>
                <h2>3.9 Fees</h2>
                <p>Baobab App does not currently charge its users to access and use the Application or the Website, however Baobab App may, at any point and at its discretion, elect to begin charging fees for use of various services of the Application, the Website and/or accounts.</p>
                <h2>3.10 Customer support</h2>
                <p>All support is currently directed through info@baobab.deals  please reach out should you have any issues, and we will get back to you as soon as possible. You queries and correspondence is extremely important to us. </p>
                <h2>3.11 Advertisements</h2>
                <p>Baobab App does not actively advertise on the Application or Website at this stage but does reserve the right to do at some stage in the future should we deem the act suitable. </p>

                <h1>4. Terms of Usage:</h1>
                <h2>4.1 User responsibilities</h2>
                <p>All Vendors hold the responsibility to maintain accuracy in the information that is provided to Baobab Applications. Vendors need to make sure that all information regarding the Vendor and items listed is truthful and complete where required. It is also the Vendors responsibility to make any updates to changes in their information required should they occur. Users should also note that they are responsible for the safeguarding of their account information. This is confidential information that users should not provide to another party. </p>
                <h2>4.2 App use compliance and prohibition</h2>
                <p>Baobab Applications are committed to the security of the services and applications. All Vendors are required to avoid exploitation and violation of the Baobab Applications. Compliance with the terms of service is essential in keeping the mobile app user experience convenient and simple. </p>


                <h2>4.3 Reproduction of Baobab Applications and Services </h2>
                <p>All Vendors are prohibited from reproducing or modifying the following:    </p>
                <h3>4.3.1) Website or application content and materials</h3>
                <h3>4.3.2) Producing offensive or illegal content</h3>
                <h3>4.3.3) Misrepresenting any information </h3>
                <h3>4.3.4) infringing on User or Vendor privacy</h3>
                <h3>4.3.5) Producing content which is deemed unethical or offensive.</h3>

                <h1>5. Content</h1>
                <h2>5.1 Application Content</h2>
                <p>The Baobab applications provide content of various items provided by third parties. This content should be respected and not infringed upon in any way. Any violation to this content will be the responsibility of the Vendor that causes the violation. Vendors are solely responsible for the accuracy of the content provided and any resulting action due to the inaccuracy of content provided by the Vendor will be the violating Vendors sole responsibility. </p>

                <h2>5.2 Copyright</h2>
                <p>Baobab Applications and content that is associated with them are copyright. It is prohibited to use the content of Baobab Applications and third parties without express consent.   </p>
                <h2>5.3 Intellectual property</h2>
                <p>Baobab is the owner of all, information ,source code, content and software that is included in the application and website. </p>
                <p>By accepting and using the services of Baobab applications you agree that Baobab applications has the right to use the content entered into the applications. </p>
                <h1>6. Other</h1>
                <h2>6.1 Account removal or Ending Services with Baobab App </h2>
                <p>The agreement and subsequent contract that has been entered into by a Vendor will end immediately should Baobab App decide to remove your access to the Application or Website, or You delete your account. Once this agreement ends for any reason a User associated to a terminated account will no longer have access to the services or the Application provided by Baobab App. </p>
                <h2>6.2 Termination of service and/or the Application</h2>
                <p>Baobab App retains the right to perform any modifications to the Application that it deems fit. The aforementioned includes suspending the Application as well as Deleting the Application. Baobab App retains the right to do so at any time or stage, without providing any type of notification. All termination of service and or ending of the services without notification is performed without liability to you. </p>
                <h2>6.3 Force Majeure</h2>
                <p>Baobab Application will not be held responsible for any lapse in services or inability to complete a service due to a situation outside of reasonable control. </p>

                <h2>6.4 Miscellaneous issues and concerns. </h2>
                <p>All concerns arising through this agreement and the use of Baobab Applications are to be directed to info@baobab.deals . In addition, Baobab Applications hold the right to make changes or further updates to this agreement at any stage. If this is the case an updated copy of the new terms and conditions will be uploaded to the website and applications for update. Baobab Applications also hold the right to revoke and remove all accounts and permissions that are associated to an account that Violates these terms of damages the website, applications or a third party in any way. </p>
              </div>
        </div>
      </div>

    </Layout>
  
    )
}

export default TermsBusinessScreen;